import { useIntl } from "react-intl";
import { SERVICES } from "../services";
import { ForgetPasswordService } from "../services/forget-password-service";
import { LoginLayout } from "../components";
import {
  FormForgetPassword,
  DefaultResponseRequest,
} from "../models/schemas/request-schema";
import { ERROR } from "../constants";
import { TextComponent, utils } from "@truenorthmortgage/olympus";
import { FormEvent, useCallback, useState } from "react";
import { notify, notifyErrors } from "../utils";
import Loading from "../components/Loading";

const ForgotPassword = () => {
  const intl = useIntl();
  const [greeting, setGreeting] = useState(
    intl.formatMessage({ id: "Forgot Your Password?" })
  );
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errors, setErrors] = useState({ email: false });
  const [is_page_loading] = useState(false);
  const [loading, setLoading] = useState(false);
  const forgetPasswordService =
    utils.injection.useInjection<ForgetPasswordService>(
      SERVICES.ForgetPasswordService
    );

  const submitReset = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      const form = { email: email } as FormForgetPassword;
      setLoading(true);
      forgetPasswordService
        .sendResetPasswordEmail(form)
        .then((response: DefaultResponseRequest) => {
          setLoading(false);
          if (true === response.success) {
            setSuccessMessage(true);
            setGreeting(intl.formatMessage({ id: "Check Your Email" }));
            if (response.message) {
              notify(response.message as string);
            }
            setErrors(response.errors);
          } else {
            if (response.errors) {
              notifyErrors(response.errors);
            }
            if (response.message) {
              notify(response.message as string, "error");
            }
            setErrors(response.errors);
          }
        })
        .catch(() => {
          setLoading(false);
          notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        });
    },
    [forgetPasswordService, location, email]
  );

  function emailCallback(val: any) {
    setEmail(val);
  }

  return true === is_page_loading ? (
    <Loading />
  ) : (
    <LoginLayout greeting={greeting} className="login-card form-password">
      <div className="inner-wrap">
        <h4 className="form-password-subheader" style={{ textAlign: "center" }}>
          {false === successMessage && (
            <span>
              It happens to the best of us! Please enter the email address
              associated with your account.
            </span>
          )}
          {true === successMessage && (
            <>
              <div>
                <span className="flex-column">
                  If you’ve entered an existing email address, we sent a
                  password reset link to
                  <strong className={"showSpaces"}> {email}</strong>
                  Password reset links are only valid for 15 minutes.
                </span>

                <br className="hide-mobile"></br>
                <span className="flex-column">
                  Didn’t receive the email?{" "}
                  <a onClick={false === loading ? submitReset : undefined}>
                    Click here to re-send
                  </a>
                </span>
              </div>
              <br></br>
              <span>
                Did you enter the wrong email?{" "}
                <a
                  className={"forget-password-link"}
                  onClick={() => setSuccessMessage(false)}
                >
                  Click here
                </a>
              </span>
            </>
          )}
        </h4>
      </div>
      {false === successMessage && (
        <form className="form-password-form">
          <TextComponent
            formData={email}
            placeholder="Email Address"
            onChange={emailCallback}
            error={errors?.email}
          />

          <button
            className="button primary"
            disabled={loading}
            onClick={submitReset}
          >
            Send Reset Email
          </button>
        </form>
      )}
      <div style={{ textAlign: "center" }}>
        {true === loading && <Loading style={{ width: "50px" }} />}
      </div>
    </LoginLayout>
  );
};

export default ForgotPassword;
